/*css used to override react-tooltip library css*/
.tooltipContainer .tooltipItem {
	color: #606060;
	font-weight: 400;
	background-color: white;
	border: 1px solid #fe9f00;
	border-radius: 8px;
	overflow-y: scroll;
	overflow-x: hidden;
	height: fit-content;
	max-height: 150px;
	pointer-events: auto;
}
