.react-datepicker__input-container input {
	display: block;
	width: 100%;
	border-radius: 0.25rem;
	margin-top: 0.25rem;
	border-color: #d1d5da;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.react-datepicker-margin-y {
	width: 100%;
	margin-top: 2px;
	margin-bottom: 2px;
}

.react-datepicker-margin-0 {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
}
